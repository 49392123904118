import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useIntl } from 'react-intl';
import useRestricted from '../hooks/useRestricted';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function Checkout() {
    useRestricted();

    const intl = useIntl();

    const [tier, setTier] = useState(1);

    const tiers = [
        {
            id: "https://app.tilopay.com/admin/recurrent/register/MjQxMg==",
            title: intl.formatMessage({ id: "checkout.type1" }),
            price: '6',
            per: intl.formatMessage({ id: "checkout.perMonth" }),
            description: [
                intl.formatMessage({ id: "checkout.terrain" }),
                intl.formatMessage({ id: "checkout.nutrition" }),
                intl.formatMessage({ id: "checkout.export" }),
                intl.formatMessage({ id: "checkout.unlimited" }),
            ],
            buttonText: intl.formatMessage({ id: "checkout.buyNow" }),
            buttonVariant: 'contained',
        },
        {
            url: "https://app.tilopay.com/admin/recurrent/register/MjM2Nw==",
            title: intl.formatMessage({ id: "checkout.type2" }),
            price: '3',
            per: intl.formatMessage({ id: "checkout.perYear" }),
            description: [
                intl.formatMessage({ id: "checkout.save24" }),
                intl.formatMessage({ id: "checkout.terrain" }),
                intl.formatMessage({ id: "checkout.nutrition" }),
                intl.formatMessage({ id: "checkout.export" }),
                intl.formatMessage({ id: "checkout.unlimited" }),
            ],
            buttonText: intl.formatMessage({ id: "checkout.buyNow" }),
            buttonVariant: 'contained',
        },
    ];

    return (
        <Container
            id="pricing"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    {intl.formatMessage({ id: "checkout.title" })}
                </Typography>
            </Box>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                >
                    <Card
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    mb: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Tabs value={tier} onChange={(ev, val) => setTier(val)}>
                                    <Tab label={tiers[0].title} value={0} />
                                    <Tab label={tiers[1].title} value={1} />
                                </Tabs>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'baseline'
                                }}
                            >
                                <Typography component="h3" variant="h2">
                                    ${tiers[tier].price}
                                </Typography>
                                <Typography component="h3" variant="h6">
                                    <Box display="inline-flex" alignItems="center" gap={0.5}>&nbsp; {tiers[tier].per}</Box>
                                </Typography>
                            </Box>
                            <Divider
                                sx={{
                                    my: 2,
                                    opacity: 0.2,
                                    borderColor: 'grey.500',
                                }}
                            />
                            {tiers[tier].description.map((line) => (
                                <Box
                                    key={line}
                                    sx={{
                                        py: 1,
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckCircleRoundedIcon
                                        sx={{
                                            width: 20,
                                            color: 'primary.main',
                                        }}
                                    />
                                    <Typography
                                        component="text"
                                        variant="subtitle2"
                                    >
                                        {line}
                                    </Typography>
                                </Box>
                            ))}
                        </CardContent>
                        <CardActions>
                            <Button
                                fullWidth
                                variant={tiers[tier].buttonVariant as 'outlined' | 'contained'}
                                href={tiers[tier].url}
                            >
                                {tiers[tier].buttonText}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>
        </Container>
    );
}