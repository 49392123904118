import { WorkspacePremium } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface NoTokensDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function NoTokensDialog({ open, onClose }: NoTokensDialogProps) {

  const intl = useIntl();
  const navigate = useNavigate();

  const goToGetTokens = () => {
    onClose();
    navigate("/checkout");
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({ id: "plans.noTokensTitle" })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: "plans.noTokensText" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>0 <WorkspacePremium /></Box> })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {intl.formatMessage({ id: "action.later" })}
        </Button>
        <Button onClick={goToGetTokens} autoFocus endIcon={<WorkspacePremium />}>
          {intl.formatMessage({ id: "action.getMoreTokens" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}