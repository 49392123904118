import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { NutritionProductPlan, PlannedNutrition } from "../../services/PlansService";
import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import NutritionProductsTable from "./NutritionProductsTable";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Settings from "@mui/icons-material/Settings";
import { AppContext } from "../../contexts/AppContext";
import { NutritionProduct } from "../../services/NutritionProductsService";
import { getProductList, getTotalNutrition, getTotalSectionNutrition } from "../../utils/NutritionUtils";
import { round } from "../../utils/UnitsUtils";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

dayjs.extend(localizedFormat);

const ParagraphLine = styled("span")(({ theme }) => ({
    verticalAlign: "baseline",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: "0.5em",
    marginRight: "0.5em"
}));

const SmallTextField = styled(TextField)(({ theme }) => ({
    maxWidth: theme.spacing(6),
    minWidth: theme.spacing(6),
    "& input": {
        fontSize: "0.875rem"
    }
}));

const TitleBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));

const SummaryTab = styled(Tab)(({ theme }) => ({
    textTransform: "none",
}));

export interface NutritionBoxProps {
    totalTime: number;
    totalNutrition: PlannedNutrition[];
    sectionNutrition: NutritionProductPlan[];
    setSectionNutrition: (sectionNutrition: NutritionProductPlan[]) => void
}

const NutritionBox: React.FC<NutritionBoxProps> = observer(({ totalTime, totalNutrition, sectionNutrition, setSectionNutrition }) => {

    const intl = useIntl();

    const { userService, nutritionProductsService } = useContext(AppContext);

    const [nutritionTableOpen, setNutritionTableOpen] = useState<boolean>(false);
    const [tab, setTab] = useState<string>("section");

    const usedNutritionProducts = sectionNutrition.map((sectionNutritionItem) => sectionNutritionItem.productId);

    const sectionNutritionValues = getTotalSectionNutrition(sectionNutrition, nutritionProductsService.nutritionProducts);
    const totalNutritionValues = getTotalNutrition(totalNutrition, nutritionProductsService.nutritionProducts);
    const totalNutritionValuesPerHour = {
        water: totalTime ? totalNutritionValues.water / (totalTime / 60) : 0,
        calories: totalTime ? totalNutritionValues.calories / (totalTime / 60) : 0,
        carbs: totalTime ? totalNutritionValues.carbs / (totalTime / 60) : 0,
        protein: totalTime ? totalNutritionValues.protein / (totalTime / 60) : 0,
        fat: totalTime ? totalNutritionValues.fat / (totalTime / 60) : 0,
        sodium: totalTime ? totalNutritionValues.sodium / (totalTime / 60) : 0,
        caffeine: totalTime ? totalNutritionValues.caffeine / (totalTime / 60) : 0
    }
    const nutritionValues: Record<string, {
        water: number,
        calories: number,
        carbs: number,
        protein: number,
        fat: number,
        sodium: number,
        caffeine: number
    }> = {
        section: sectionNutritionValues,
        plan: totalNutritionValues,
        hour: totalNutritionValuesPerHour
    }

    const productList = getProductList(totalNutrition, nutritionProductsService.nutritionProducts);


    useEffect(() => {
        if (!userService.user?.id) {
            return;
        }
        nutritionProductsService.loadNutritionProducts(userService.user.id)
    }, [nutritionProductsService, userService.user?.id]);

    const addNutrition = () => {
        setSectionNutrition([...sectionNutrition, { productId: "empty", quantity: 0 }]);
    }

    const onChangeProduct = (productId: string, index: number) => {
        if (productId === "empty") {
            return;
        }
        if (productId === "add") {
            setNutritionTableOpen(true);
        } else {
            const newSectionNutrition = [...sectionNutrition];
            newSectionNutrition[index].productId = productId;
            setSectionNutrition(newSectionNutrition);
        }
    }

    const onChangeQuantity = (productId: string, quantity: number) => {
        const newSectionNutrition = [...sectionNutrition];
        const nutritionProductIndex = newSectionNutrition.findIndex((sectionNutritionItem) => sectionNutritionItem.productId === productId);
        if (nutritionProductIndex > -1) {
            newSectionNutrition[nutritionProductIndex].quantity = quantity;
            setSectionNutrition(newSectionNutrition);
        }
    }

    const deleteProduct = (nutritionProductId: string) => {
        const newSectionNutrition = sectionNutrition.filter((sectionNutritionItem) => sectionNutritionItem.productId !== nutritionProductId);
        setSectionNutrition(newSectionNutrition);
    }

    return (
        <Box p={1}>
            <TitleBox display="flex" alignItems="center" justifyContent="space-between" marginY={1}>
                <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "plans.nutritionTitle" })}</Typography>
                <Button startIcon={<Settings />} onClick={() => setNutritionTableOpen(true)} size="small">
                    {intl.formatMessage({ id: "plans.nutritionProducts" })}
                </Button>
            </TitleBox>
            {sectionNutrition.map((sectionNutritionItem, index) => (
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1} key={sectionNutritionItem.productId}>
                    <Box display="flex" alignItems="baseline">
                        <Select
                            value={sectionNutritionItem.productId}
                            label="Product"
                            size="small"
                            variant="standard"
                            onChange={(event) => onChangeProduct(event.target.value as string, index)}
                            style={{ fontSize: "0.875rem" }}
                        >
                            <MenuItem value="empty"><b>{intl.formatMessage({ id: "plans.chooseProduct" })}</b></MenuItem>
                            {(sectionNutritionItem.productId === "water" || !usedNutritionProducts.includes("water")) && <MenuItem value="water">{intl.formatMessage({ id: "data.waterLiters" })}</MenuItem>}
                            {nutritionProductsService.nutritionProducts.map((product: NutritionProduct) => (
                                !!product.id && (sectionNutritionItem.productId === product.id || !usedNutritionProducts.includes(product.id)) ? <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem> : null
                            ))}
                            <MenuItem value="add"><b>{intl.formatMessage({ id: "plans.addFavoriteProduct" })}</b></MenuItem>
                        </Select>
                    </Box>
                    <ParagraphLine />
                    <Box display="flex" alignItems="baseline">
                        <SmallTextField hiddenLabel variant="standard" size="small" type="number" value={sectionNutritionItem.quantity} onChange={(ev) => onChangeQuantity(sectionNutritionItem.productId, +ev.target.value)} InputProps={{ inputProps: { step: 'any' } }} />
                        <IconButton onClick={() => deleteProduct(sectionNutritionItem.productId)} size="small">
                            <Delete fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Box display="flex" alignItems="baseline" justifyContent="end" marginY={1}>
                <Button startIcon={<Add />} size="small" onClick={addNutrition} disabled={nutritionProductsService.nutritionProducts.length + 2 <= usedNutritionProducts.length || !!sectionNutrition.find(sN => sN.productId === "empty")}>{intl.formatMessage({ id: "plans.addNutrition" })}</Button>
            </Box>

            <Box textAlign="center" marginY={1}>
                <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "plans.nutritionSummary" })}</Typography>
            </Box>
            <Divider />

            <Tabs
                value={tab}
                onChange={(ev, value) => setTab(value)}
                variant="fullWidth"
            >
                <SummaryTab
                    value="section"
                    label={intl.formatMessage({ id: "data.totalNutritionForSection" })}
                />
                <SummaryTab
                    value="plan"
                    label={intl.formatMessage({ id: "data.totalNutritionForPlan" })}
                />
                <SummaryTab
                    value="hour"
                    label={intl.formatMessage({ id: "data.totalNutritionPerHour" })}
                />
            </Tabs>

            <Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.waterTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].water, 2)} L</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.caloriesTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].calories, 2)} kcal</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.carbsTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].carbs, 2)} g</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.proteinTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].protein, 2)} g</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.fatTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].fat, 2)} g</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.sodiumTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].sodium, 2)} mg</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.caffeineTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{round(nutritionValues[tab].caffeine, 2)} mg</Typography>
                </Box>
            </Box>


            <Box textAlign="center" marginY={1}>
                <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "plans.productList" })}</Typography>
            </Box>
            <Divider />
            {productList.map((product, index) => (
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1} key={index}>
                    <Typography variant="body2">{product.name}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2">{product.quantity}</Typography>
                </Box>
            ))}
            {
                productList.length === 0 && <Box textAlign="center" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "plans.noProducts" })}</Typography>
                </Box>
            }

            <Dialog
                open={nutritionTableOpen}
                onClose={() => {
                    setNutritionTableOpen(false)
                    nutritionProductsService.loadNutritionProducts(userService.user?.id || "")
                }}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    {intl.formatMessage({ id: "plans.nutritionProducts" })}
                </DialogTitle>
                <DialogContent>
                    <NutritionProductsTable />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setNutritionTableOpen(false)
                        nutritionProductsService.loadNutritionProducts(userService.user?.id || "")
                    }}>
                        {intl.formatMessage({ id: "action.done" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
});

export default NutritionBox;