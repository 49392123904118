import Box from "@mui/material/Box";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react-lite";
import { RichTextEditor, MenuControlsContainer, MenuSelectHeading, MenuDivider, MenuButtonBold, MenuButtonItalic, MenuButtonStrikethrough, MenuButtonOrderedList, MenuButtonBulletedList, MenuButtonHorizontalRule, RichTextEditorRef } from "mui-tiptap";
import { useEffect, useRef } from "react";

export interface NoteBoxProps {
    currentSection: number;
    note: string;
    onChangeNote: (note: string) => void;
}

const NoteBox: React.FC<NoteBoxProps> = observer(({ currentSection, note, onChangeNote }) => {

    const rteRef = useRef<RichTextEditorRef>(null);

    useEffect(() => {
        rteRef.current?.editor?.commands.setContent(note);
    }, [currentSection]);

    return (
        <Box p={1}>
            <RichTextEditor
                ref={rteRef}
                onUpdate={
                    ({ editor, transaction }) => {
                        onChangeNote(editor.getHTML());
                    }
                }
                extensions={[
                    StarterKit
                ]}
                // Optionally include `renderControls` for a menu-bar atop the editor:
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectHeading />
                        <MenuDivider />
                        <MenuButtonBold />
                        <MenuButtonItalic />
                        <MenuButtonStrikethrough />
                        <MenuDivider />
                        <MenuDivider />
                        <MenuButtonOrderedList />
                        <MenuButtonBulletedList />
                        <MenuDivider />
                        <MenuButtonHorizontalRule />
                    </MenuControlsContainer>
                )}
            />
        </Box>
    );
});

export default NoteBox;