import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface PermissionDialogProps {
  open: boolean;
  title: string;
  text: string;
  video: string;
  onClose: () => void;
}

export default function PermissionDialog({ open, onClose, title, text, video }: PermissionDialogProps) {

  const intl = useIntl();
  const navigate = useNavigate();

  const goToChekout = () => {
    onClose();
    navigate("/checkout");
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" marginBottom={1}>
          <video width="100%" autoPlay loop>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {intl.formatMessage({ id: "action.later" })}
        </Button>
        <Button onClick={goToChekout} variant="contained" autoFocus>
          {intl.formatMessage({ id: "action.getPro" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}