import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import useRestricted from '../hooks/useRestricted';
import { WorkspacePremium } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ImageColumGrid from '../components/common/ImageColumGrid';
import { AppContext } from '../contexts/AppContext';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { Permission } from '../services/UserService';
import { useEffect } from 'react';
import dayjs from 'dayjs';

const Payment: React.FC = observer(() => {
  useRestricted();

  const intl = useIntl();

  const navigate = useNavigate();

  const { userService } = React.useContext(AppContext);

  const [currentPermission, setCurrentPermission] = React.useState<Permission>(Permission.loading);

  useEffect(() => {
    checkSubscription();
  }, [userService.user]);

  const checkSubscription = async () => {
    let permission = Permission.loading;
    if (userService.user?.subscriptionStatus === "inactive") {
      permission = Permission.free;
    }

    permission = userService.isLoadingUser ?
      Permission.loading :
      userService.user?.permissions?.includes("admin") ? Permission.admin :
        (userService.user?.proExpDate && dayjs(userService.user.proExpDate).add(1, "day").isAfter(dayjs(), "day") &&
          userService.user.subscriptionStatus === "active"
        ) ?
          Permission.pro : Permission.loading;

    setCurrentPermission(permission);

    if (permission === Permission.loading) {
      setTimeout(async () => {
        await userService.refreshUser();
        checkSubscription();
      }, 10000);
    }
  };


  const goToPlans = () => {
    navigate("/plans");
  }

  const contactSupport = () => {
    window.open(`mailto: support@timeontrails.com?subject=Error on payment&body=I have a problem with the payment.`);
  }

  return (
    <ImageColumGrid
      image={`${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {
        (!userService.user || currentPermission === Permission.loading) ? (

          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h2" variant="h4" color="text.primary">
                {intl.formatMessage({ id: "payment.loading" })}
              </Typography>
              <Typography variant="body1" color="text.secondary" marginTop={2}>
                {intl.formatMessage({ id: "payment.loadingDescription" }, { icon: <WorkspacePremium /> })} <br />
              </Typography>
            <Box marginTop={3} width="100%" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Box>
        ) :
          (currentPermission === Permission.free) ? (
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h2" variant="h4" color="text.primary">
                {intl.formatMessage({ id: "payment.error" })}
              </Typography>
              <Typography variant="body1" color="text.secondary" marginTop={2}>
                {intl.formatMessage({ id: "payment.errorDescription" })} <br />
              </Typography>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={contactSupport}
              >
                {intl.formatMessage({ id: "payment.contactSupport" })}
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={goToPlans}
              >
                {intl.formatMessage({ id: "payment.goToPlans" })}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h2" variant="h4" color="text.primary">
                {intl.formatMessage({ id: "payment.thankyou" })}
              </Typography>
              <Typography variant="body1" color="text.secondary" marginTop={2}>
                {intl.formatMessage({ id: "payment.result" })} <br />
              </Typography>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={goToPlans}
              >
                {intl.formatMessage({ id: "payment.goToPlans" })}
              </Button>
            </Box>
          )
      }
    </ImageColumGrid>
  );
})

export default Payment;