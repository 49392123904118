import { useIntl } from "react-intl";
import { Link, Outlet, useLocation } from "react-router-dom";
import Logo from "../components/common/Logo";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styled from "@mui/material/styles/styled";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import DirectionsRun from "@mui/icons-material/DirectionsRun";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Help from "@mui/icons-material/Help";
import Person from "@mui/icons-material/Person";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import dayjs from "dayjs";
import { Permission } from "../services/UserService";

const Main = styled('main')(({ theme }) => ({
  marginLeft: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    marginTop: theme.spacing(8),
  },
  "@media print": {
    margin: 0,
  }
}));

const Navbar = styled(Drawer)(({ theme }) => ({
  "@media print": {
    display: "none"
  }
}));

const NavIcon = styled(ListItemIcon)<{ selected?: boolean }>(({ theme, selected }) => ({
  minWidth: 0,
  justifyContent: 'center',
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
}));

export interface INavigationOption {
  name: string;
  href: string;
  icon: React.ElementType;
  routes: string[];
  index?: boolean;
};

const NavListItem: React.FC<INavigationOption> = (navigationOption) => {

  const location = useLocation();
  const currentPath = location?.pathname;

  return (
    <Tooltip title={navigationOption.name} placement="right" arrow>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 2.5,
          }}
          component={Link}
          to={navigationOption.href}
        >
          <NavIcon selected={(navigationOption.index && currentPath === "/") || navigationOption.routes.some(route => currentPath.includes(route))}>
            {navigationOption.icon && <navigationOption.icon />}
          </NavIcon>
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );

}

const Root: React.FC = observer(() => {

  const intl = useIntl();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { userService } = useContext(AppContext);

  const navigationOptions = {
    main: [
      { name: intl.formatMessage({ id: "nav.plans" }), href: '/plans', icon: DirectionsRun, routes: ['/plans'], index: true },
      //{ name: intl.formatMessage({ id: "nav.search" }), href: '/search', icon: Search, routes: ['/search'] },
    ],
    secondary: [
      { name: intl.formatMessage({ id: "nav.help" }), href: '/help', icon: Help, routes: ['help'] },
      { name: intl.formatMessage({ id: "nav.account" }), href: '/account', icon: Person, routes: ['/account', '/sign-in', 'sing-up', 'forgot-password'] },
    ],
  };

  if (!userService.user) {
    navigationOptions.main = [];
    navigationOptions.secondary = navigationOptions.secondary.filter(option => option.href !== '/account');
  } else {
    const permission = userService.isLoadingUser ?
      Permission.loading :
      userService.user?.permissions?.includes("admin") ? Permission.admin :
        (userService.user?.proExpDate && dayjs(userService.user.proExpDate).add(1, "day").isAfter(dayjs(), "day") &&
          userService.user.subscriptionStatus === "active"
        ) ?
          Permission.pro : Permission.free;
    
    if (permission === Permission.free) {
      navigationOptions.main.push(
        { name: intl.formatMessage({ id: "action.getPro" }), href: '/checkout', icon: ShoppingCart, routes: ['/checkout'], index: false }
      );
    }
  }

  return (
    <Box>
      <Navbar variant="permanent" anchor={isUpMD ? "left" : "top"} sx={{ display: 'flex' }}
        PaperProps={{ sx: { flexDirection: isUpMD ? "column" : "row", justifyContent: "space-between", alignItems: "center" } }}
      >
        <List sx={{ display: isUpMD ? "block" : "flex" }}>
          {navigationOptions.main.map((navigationOption) => (
            <NavListItem key={navigationOption.name} {...navigationOption} />
          ))}
        </List>
        {
          !isUpMD && (
            <Logo width={80} />
          )
        }
        <List sx={{ marginTop: 'auto', display: isUpMD ? "block" : "flex" }}>
          {navigationOptions.secondary.map((navigationOption) => (
            <NavListItem key={navigationOption.name} {...navigationOption} />
          ))}
        </List>
      </Navbar>
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
});

export default Root;
