import { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Permission } from "../services/UserService";

export default function useRestricted(permission?: string) {

    const { userService } = useContext(AppContext);
    const navigate = useNavigate();

    const location = useLocation();
    const currentPath = location?.pathname;

    useEffect(() => {
        if (userService.isLoadingUser) {
            return;
        }

        userService.refreshUser().then(() => {

            if (!userService.user) {
                navigate("/sign-in", { state: { from: currentPath } });
            } else if (permission && !userService.user.permissions?.includes(permission)) {
                navigate("/");
            }
        });

    }, [currentPath, userService.isLoadingUser]);

    return userService.isLoadingUser ?
        Permission.loading :
        userService.user?.permissions?.includes("admin") ? Permission.admin : 
            (userService.user?.proExpDate && dayjs(userService.user.proExpDate).add(1, "day").isAfter(dayjs(), "day") &&
                userService.user.subscriptionStatus === "active"
            ) ?
                Permission.pro : Permission.free; 
}