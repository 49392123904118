import { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import { observer } from "mobx-react-lite";
import useRestricted from "../hooks/useRestricted";
import ImageColumGrid from "../components/common/ImageColumGrid";
import React from "react";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

const Account: React.FC = observer(() => {

  useRestricted();

  const { userService } = useContext(AppContext);
  const intl = useIntl();

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [name, setName] = React.useState<string>("");
  const [language, setLanguage] = React.useState<string>("");
  const [distanceUnits, setDistanceUnits] = React.useState<string>("");
  const [elevationUnits, setElevationUnits] = React.useState<string>("");

  useEffect(() => {
    if (userService.user) {
      setName(userService.user.displayName || "");
      setLanguage(userService.user.language || "en");
      setDistanceUnits(userService.user.distanceUnits || "km");
      setElevationUnits(userService.user.elevationUnits || "m");
    }
  }, [userService.user]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleChangeLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newLang: string | null,
  ) => {
    if (newLang !== null) {
      setLanguage(newLang);
    }
  };

  const handleChangeDistanceUnits = (
    event: React.MouseEvent<HTMLElement>,
    newDistanceUnits: string | null,
  ) => {
    if (newDistanceUnits !== null) {
      setDistanceUnits(newDistanceUnits);
    }
  };

  const handleChangeElevationUnits = (
    event: React.MouseEvent<HTMLElement>,
    newElevationUnits: string | null,
  ) => {
    if (newElevationUnits !== null) {
      setElevationUnits(newElevationUnits);
    }
  };

  const handleEditUser = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('name')) {
      setError(intl.formatMessage({ id: "error.nameRequired" }));
      setLoading(false);
      return;
    }

    try {
      if (!userService.user) {
        setError(intl.formatMessage({ id: "error.unknown" }));
        setLoading(false);
        return;
      }
      await userService.updateUserData({ ...userService.user, displayName: data.get('name') as string, language, distanceUnits, elevationUnits });
      setLoading(false);
    } catch (error) {
      setError(intl.formatMessage({ id: "error.unknown" }));
      setLoading(false);
    }
  };

  return (
    <ImageColumGrid
      image={`${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {userService.user && (<Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {intl.formatMessage({ id: "account.title" })}
        </Typography>
        <Box component="form" noValidate onSubmit={handleEditUser} sx={{ mt: 1 }} ref={formRef}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={intl.formatMessage({ id: "form.email" })}
            name="email"
            autoComplete="email"
            disabled
            onChange={() => setError(null)}
            value={userService.user.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={intl.formatMessage({ id: "form.name" })}
            name="name"
            autoComplete="name"
            autoFocus
            onChange={handleChangeName}
            value={name}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between" m={1}>

            <Typography variant="body2">
              {intl.formatMessage({ id: "form.language" })}
            </Typography>
            <ToggleButtonGroup
              value={language}
              exclusive
              onChange={handleChangeLanguage}
              aria-label="language"
            >
              <ToggleButton value="en" aria-label="english">
                EN
              </ToggleButton>
              <ToggleButton value="es" aria-label="spanish">
                ES
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" m={1}>

            <Typography variant="body2">
              {intl.formatMessage({ id: "form.distanceUnits" })}
            </Typography>
            <ToggleButtonGroup
              value={distanceUnits}
              exclusive
              onChange={handleChangeDistanceUnits}
              aria-label="distance units"
            >
              <ToggleButton value="km" aria-label="kilometers">
                {intl.formatMessage({ id: "km" })}
              </ToggleButton>
              <ToggleButton value="miles" aria-label="miles">
                {intl.formatMessage({ id: "miles" })}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" m={1}>

            <Typography variant="body2">
              {intl.formatMessage({ id: "form.elevationUnits" })}
            </Typography>
            <ToggleButtonGroup
              value={elevationUnits}
              exclusive
              onChange={handleChangeElevationUnits}
              aria-label="elevation units"
            >
              <ToggleButton value="m" aria-label="meters">
                {intl.formatMessage({ id: "m" })}
              </ToggleButton>
              <ToggleButton value="feet" aria-label="feet">
                {intl.formatMessage({ id: "feet" })}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {
            error && <Alert severity="error">{error}</Alert>
          }
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            {intl.formatMessage({ id: "action.save" })}
          </LoadingButton>

          <Button
            fullWidth
            onClick={userService.signOutUser}
          >
            {intl.formatMessage({ id: "account.signOut" })}
          </Button>
        </Box>
      </Box>)}
    </ImageColumGrid>
  );
});

export default Account;
