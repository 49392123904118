import { useIntl } from "react-intl";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { SectionData } from "../../services/PlansService";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";

const SectionChangerBox = styled(Box)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.divider}`
}));

interface SectionChangerProps {
  currentSection: number;
  changeSection: (section: number) => void;
  sectionsData: SectionData[];
}

const SectionChanger: React.FC<SectionChangerProps> = observer(({ currentSection, changeSection, sectionsData }) => {

  const intl = useIntl();

  const numSections = sectionsData.filter((s) => s.type === "section").length;

  const [currentSectionData, setCurrentSectionData] = useState<SectionData | null>(sectionsData[currentSection]);

  useEffect(() => {
    setCurrentSectionData(sectionsData[currentSection]);
  }, [currentSection, sectionsData]);

  return (
    <SectionChangerBox display="flex" alignItems="center" justifyContent="space-between" paddingY={1} paddingX={2}>
      <IconButton onClick={() => changeSection(currentSection - 1)} size="small" disabled={currentSection === 0}>
        <ChevronLeft fontSize="small" />
      </IconButton>
      {currentSectionData && <Typography variant="body2" fontWeight="bold">{currentSectionData.type === "section" ? intl.formatMessage({ id: "data.section" }, { num: Math.ceil(currentSection / 2), total: numSections }) :
        currentSectionData.type === "start" ? intl.formatMessage({ id: "plans.start" }) :
          currentSectionData.type === "end" ? intl.formatMessage({ id: "plans.end" }) :
            `${intl.formatMessage({ id: "plans.CP" })} ${Math.ceil(currentSection / 2)}`}</Typography>}
      <IconButton onClick={() => changeSection(currentSection + 1)} size="small" disabled={currentSection === sectionsData.length - 1}>
        <ChevronRight fontSize="small" />
      </IconButton>
    </SectionChangerBox>
  );
});

export default SectionChanger;