import { observer } from "mobx-react-lite";
import { Plan } from "../../services/PlansService";
import { defaultSvg } from "../../utils/latlngSVG";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { convertDistance, convertElevation, round } from "../../utils/UnitsUtils";
import { AppContext } from "../../contexts/AppContext";
import React from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PlanBox = styled(Box)(({ theme }) => ({
    outline: `solid ${theme.palette.divider}`,
    outlineWidth: 1,
    cursor: "pointer",
    "&:hover": {
        outline: `solid ${theme.palette.common.black}`,
    },
}));

const PlanImageContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[200],
}));

const PlanImage = styled("div")(({ theme }) => ({
    width: theme.spacing(20),
    height: theme.spacing(20),
    display: "flex",
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[200],
    '& path': {
        strokeWidth: 15,
    },
}));

export const PlanItem: React.FC<{ plan: Plan, onClick: () => void }> = observer(({ plan, onClick }) => {

    const intl = useIntl();

    const theme = useTheme();
    const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

    const { userService } = React.useContext(AppContext);

    const distanceUnits = userService.user?.distanceUnits || "km";
    const elevationUnits = userService.user?.elevationUnits || "m";

    return (
        <Grid item xs={12} lg={6}>

            <PlanBox display="flex" justifyContent="center" flexDirection={isUpMD ? "row" : "column"} padding={4} onClick={onClick}>
                <PlanImageContainer>
                    <PlanImage dangerouslySetInnerHTML={{ __html: plan.svg || defaultSvg }} />
                </PlanImageContainer>

                <Grid container marginX={isUpMD ? 4 : 0} marginTop={isUpMD ? 0 : 4}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{plan.name}</Typography>
                    </Grid>
                    <Grid item xs={6} marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                        <Typography variant="body2" fontSize={18}>{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(plan.distance, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                    </Grid>
                    <Grid item xs={6} marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.dateTitle" })}</Typography>
                        <Typography variant="body2" fontSize={18}>{dayjs(plan.date).locale(intl.locale).format("ll")}</Typography>
                    </Grid>
                    <Grid item xs={6} marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                        <Typography variant="body2" fontSize={18}>{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Grid>
                    <Grid item xs={6} marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                        <Typography variant="body2" fontSize={18}>{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Grid>
                </Grid>
            </PlanBox>
        </Grid>
    );
});