import Fab from "@mui/material/Fab";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import styled from "@mui/material/styles/styled";
import Terrain from "@mui/icons-material/Terrain";
import PermissionDialog from "../common/PermissionDialog";
import { useIntl } from "react-intl";
import { Permission } from "../../services/UserService";
import useRestricted from "../../hooks/useRestricted";
import Chip from "@mui/material/Chip";

const TerrainControlFab = styled(Fab)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(14),
}));

const ProChip = styled(Chip)(({ theme }) => ({
    position: "absolute",
    bottom: theme.spacing(-1),
    right: theme.spacing(-1),
    height: 16,
    fontSize: 10,
}));

export const TerrainControl = observer(() => {

    const { mapService } = useContext(AppContext);

    const permission = useRestricted();

    const intl = useIntl();

    const [open, setOpen] = useState(false);

    const onClick = () => {

        if (permission === Permission.loading) {
            return;
        }

        if (permission !== Permission.free) {
            mapService.toggleTerrain();
        } else {
            setOpen(true);
        }
    }

    return (
        <>
            <TerrainControlFab size="small">
                <Terrain onClick={onClick} />
                {permission === Permission.free && <ProChip label="Pro" size="small" color="warning" />}
            </TerrainControlFab>
            <PermissionDialog
                open={open}
                onClose={() => setOpen(false)}
                title={intl.formatMessage({ id: "permission.terrainTitle" })}
                text={intl.formatMessage({ id: "permission.terrainText" })}
                video="/videos/3dterrain.mp4"
            />
        </>
    );
});