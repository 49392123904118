import * as React from 'react';
import useRestricted from '../hooks/useRestricted';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { PlotContainer } from '../components/common/PlotContainer';
import { Map } from '../components/plans/Map';
import { getLatLngForDistance } from '../utils/UnitsUtils';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Permission } from '../services/UserService';

const Insta: React.FC = observer(() => {

  useRestricted("admin");

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { userService, plansService, mapService } = React.useContext(AppContext);

  const { userId, planId } = useParams();

  const navigate = useNavigate();


  const plan = plansService.plan;

  React.useEffect(() => {
    if (!userService.user?.id || !planId || !userId || (userService.user?.id !== userId)) {
      if (!userService.isLoadingUser) {
        navigate("/plans");
      }
      return;
    }

    if (plan && plan.id === planId) {
      return;
    }

    plansService.loadPlan(userId, planId).then((plan) => {
      if (plan) {
        plansService.parseCurrentPlanGpxFile().then((sucess) => {
          if (!sucess) {
            navigate("/plans");
          }
        });
      } else {
        navigate("/plans");
      }
    });
  }, [userService.isLoadingUser, plansService, planId, userId, userService.user?.id, navigate, plan]);

  React.useEffect(() => {
    if (plansService.bbox && isUpMD) {
      mapService.init(plansService.bbox, Permission.admin, false);
    }
  }, [plansService.bbox, mapService, isUpMD]);

  const handleGraphHover = (distance: number | null) => {
    if (!distance) {
      return;
    }
    const [lng, lat] = getLatLngForDistance(distance, plansService.latlng, plansService.distancePoints)
    mapService.setPointPostion(lat, lng);
  }

  if (!plan) {
    return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
  }

  return (
    plan && (
      <Box display="flex" height={isUpMD ? "100vh" : "auto"} overflow="hidden">
        {isUpMD && <Box display="flex" flexDirection="column" width="100%">

          <Box width={800} height={400} position="relative">
            <Map />
          </Box>
          <Box padding={1} zIndex={10} maxWidth={1200} maxHeight={400}>
            <PlotContainer
              id="full-plot"
              data={plansService.elevationSeries}
              dimensions={{ width: 1200, height: 400, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
              breaks={plansService.plan.controlPoints ? plansService.plan.controlPoints.slice(1, plansService.plan.controlPoints.length - 1).map((v) => v.distance * 1000) : []}
              setHoveredDistance={handleGraphHover}
              insta={true}
            />
          </Box>
        </Box>}
      </Box>
    )
  );
});

export default Insta;