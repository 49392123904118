import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import { AppContext } from '../../contexts/AppContext';

const ForgotPassword: React.FC = () => {

    const intl = useIntl();
    const navigate = useNavigate();

    const { userService } = React.useContext(AppContext);

    const [error, setError] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean>(false);

    const handleForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('email')) {
            return;
        }
        setError(null);
        setSuccess(false);
        try {
            userService.forgotPassword(data.get('email')?.toString() || "");
            setSuccess(true);
        } catch (error) {
            setError("error.unknown");
        }
    };
    
    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start"}} onClick={() => navigate("/sign-in")}>
                {intl.formatMessage({ id: "auth.backToSignIn" })}
            </Button>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "auth.forgotPassword" })}
            </Typography>
            <Box component="form" noValidate onSubmit={handleForgotPassword} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={intl.formatMessage({ id: "form.email" })}
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                {
                    error && <Alert severity="error">{intl.formatMessage({ id: intl.formatMessage({ id: "error.unknown" })})}</Alert>
                }
                {
                    success && <Alert severity="success">{intl.formatMessage({ id: "auth.forgotPasswordSuccess" })}</Alert>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {intl.formatMessage({ id: "auth.resetPassword" })}
                </Button>
                <Copyright sx={{ mt: 5 }} />
            </Box>
        </Box>
    );
};

export default ForgotPassword;