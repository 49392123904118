import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

const BetaText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: -15,
  right: -15,
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
    bottom: -8,
    right: -8,
  },
}));

interface LogoProps {
  width: number;
}

export default function Logo({width}: LogoProps) {

  return (
    <Box position="relative">
      <img
          style={{ width }}
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Time on Trails"
      />
    </Box>
  );
}